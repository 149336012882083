<template>
	<div>

		<!-- mask to close field if user click outside the field -->
		<div 
		class="inputmask" 
		v-if="isOptionsListOpen"
		@click="closeWithoutSelection"
		></div>

		<div class="selectfield">
			<label :for="fieldname"><span v-html="metaData.description"></span><span v-if="isRequired" class="req">&nbsp;*</span></label>

			<div class="dropdown" :class="{'open': isOptionsListOpen}">

				<input 
				type="text"
				autocomplete="off"
				:id="fieldname"
				v-model="textInField"
				@input="userChangedInput"
				:placeholder = "placeholder"
				:class="{'hasSuccess': isInSuccess, 'hasError': isInError, 'isLoading': isLoading}"
				@keyup.prevent.down="keyPressedDown"
				@keyup.prevent.up = "keyPressedUp"
				@keyup.prevent.enter = "keyPressedEnter"
				:disabled="isDisabled"
				>

				<!-- Toggler -->
				<div class="toggle" @click="toggleListOpeness()">
					<span class="arrow-up" v-show="isOptionsListOpen">▲</span>
					<span class="arrow-down" v-show="!isOptionsListOpen">▼</span>
				</div>

				<!-- Options list -->
				<ul	v-show="isOptionsListOpen" class="options-list">
					<li 
					v-for="(option, index) in optionsList"
					:key="index"
					@click="selectThisOption(option, index)"
					:class="{'active': currentActiveOption === index}"
					>{{option.description}}</li>
					<li v-show="noResult" @click="closeWithoutSelection">{{$t('inputLabel.noResult')}}</li>
				</ul>

			</div>
		</div>

		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper"><span v-html="helper"></span></div>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	import customLog from "../mixins/CustomLog";
	import config from "../config";

	export default {
		name:"SelectMeta",
		mixins: [
			customLog,
			FormFields,
			],
		props: {
			dataSource: {
				type: String,
				required: true,
			},
			caractersBeforeOpenList : {
				type: Number,
				required: false,
			default: 3
			},
			autocomplete: {
				type: Boolean,
				required: true,
			},
			isDisabled: {
				type: Boolean,
				required: false,
			default: false,
			}
		},
		data(){
			return {
				metaData: [{
					leadmanagementmetadata: {
						description: "",
						leadmanagementmetadatavalue: []
					}
				}],
				isOptionsListOpen: false,
				optionsFullList: [],
				displayedInTheField: null,
				config: config,
				currentActiveOption: null,
				noResult: false,
			}
		},
		computed: {

			textInField: {
				get(){
					return this.displayedInTheField;
				},
				set(newVal){
					this.displayedInTheField = newVal;
				}
			},

			metaOptionsList(){
				let list = this.metaData.leadmanagementmetadatavalue;
				if(typeof list !== "undefined") {
					for (let item of list){
						item.description = item.description.trim();
					}
				} else {
					return [];
				}
				return list;
			},

		},
		methods: {

			keyPressedDown(){
				if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length -1){
					this.downOneItem();
				}
				if(!this.isOptionsListOpen){
					this.makeNewOptionsList(false);
					this.isOptionsListOpen = true;
				}
			},

			keyPressedUp(){
				if(this.isOptionsListOpen && this.currentActiveOption > 0){
					this.upOneItem();
				}else{
					this.closeOptionsList();
					this.currentActiveOption = null;
				}
			},

			keyPressedEnter(){
				if(this.isOptionsListOpen){
					this.log("Enter when list is open", 'low');
					this.selectThisOption(this.optionsList[this.currentActiveOption], this.currentActiveOption);
				}
			},

			upOneItem(){
				this.currentActiveOption -= 1;
			},

			downOneItem(){
				this.currentActiveOption += 1;
			},

			selectThisOption(option, index){
				this.log(`User choose item ${option.id} - ${option.description}`, 'low');
				this.displayedInTheField = option.description;
				this.currentActiveOption = index;
				this.closeOptionsList(option.id);
			},

			closeWithoutSelection(){
				this.log("Closing the optionList without selection", 'low');
				this.textInField = "";
				this.closeOptionsList();
				this.currentActiveOption = null; /* maybe could be inchanged instead? */
				this.runValidationProcess();
				this.$emit('update:modelValue', null);
			},

			toggleListOpeness(){
				this.isOptionsListOpen = !this.isOptionsListOpen;
				if(this.isOptionsListOpen){
					this.displayedInTheField = "";
					this.makeNewOptionsList(this.autocomplete);
				}
			},

			emptyAndCloseList(){
				this.optionsList = [];
				this.closeOptionsList();
			},

			userChangedInput(){
				/* Refresh list of options */
				/* User left no caracters => options list is empty */
				if(this.displayedInTheField.length === 0){
					this.emptyAndCloseList();
				}
				/* User type some caracters => options list matching strings */
				if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
					this.makeNewOptionsList(this.autocomplete);
					if(!this.isOptionsListOpen){ this.openOptionsList(); }
				}
				/* Display "no result" instead of nothing */
				this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.optionsList.length < 1);
			},

			makeNewOptionsList(bool){
				/* If autocomplete is true, it's a partial list */
				if(bool){
					let userInput = this.displayedInTheField.toLowerCase();
					this.optionsList = this.optionsFullList.filter(option => option.description.toLowerCase().includes(userInput));
				}
				/* If autocomplete is false, it's a full list */
				if(!bool){
					this.optionsList = this.optionsFullList;
				}
			},

			openOptionsList(){
				/* User open the option list */
				this.log("Opening option list", 'low');
				/* remove previous options */
				this.optionsList = [];
				/* recreate new options list */
				this.makeNewOptionsList(this.autocomplete);
				/* DOM open the list */
				this.isOptionsListOpen = true;
			},

			closeOptionsList(id){
				/* User leave the option list */
				this.noResult = false;
				this.log("Closing option list", 'low');
				this.isOptionsListOpen = false;
				this.runValidationProcess(id);
			},

			isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
				let validOptionsList = [];
				for(let item in this.optionsFullList){			
					validOptionsList.push(this.optionsFullList[item].description.trim().toLowerCase());
				}
				return validOptionsList.includes(this.displayedInTheField.trim().toLowerCase());
			},

			sendDataToForm(id){
				this.log(`SelectMeta transmitting id ${id} to parent`, 'low');
				this.$emit('update:modelValue', id);
			},

			sendValidityOfTheFieldToTheForm(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			whatToDoInCaseFieldIsValidated(id){
				this.log("Validation return true", 'success');
				this.sendDataToForm(id);
				this.giveSuccess();
				this.sendValidityOfTheFieldToTheForm(true);
			},

			whatToDoInCaseFieldIsNotValidated(){
				this.log("Validation return false", 'warning');
				this.giveError();
				this.errorMessage = this.$t('inputsTexts.dropdown.missingData');
				this.sendValidityOfTheFieldToTheForm(false);
			},

			runValidationProcess(id){
				this.removeSuccessOrError();
				this.isThisFieldValid() ? this.whatToDoInCaseFieldIsValidated(id) : this.whatToDoInCaseFieldIsNotValidated();
			},

			apiForDropdownIsSuccessful(data){
				this.log("Data gathered from API", 'low');
				this.log(data);
				this.metaData = data;
				this.optionsFullList = this.metaOptionsList;
			},

			apiForDropdownHasFailed(error){
				this.log("apiForDropdownHasFailed", 'alert');
				this.log(error);
				if(error.response.status === 503){
					this.setNotLoading();
					this.$emit('maintenanceDetected');
				}else{
					console.log("Cannot get list from API");
					console.error(error);
				}
			},

			requestNewListFromAPI(){
				// this.optionsList = [{id:0, description: "Lorem ipsum"}];
				this.setLoading();
				this.axios.get(`/dropdown/${config.idlead}/metadata/${this.dataSource}`) 
				.then(resp => {
					this.apiForDropdownIsSuccessful(resp.data);
				})
				.catch(error => {
					this.apiForDropdownHasFailed(error);
				})
				.then(()=>{
					this.log(`List from API are put in place in ${this.fieldname}.`, 'low');
				});
				this.setNotLoading();
			},

		},
		watch:{
			/* If locale change, must reload the list to take lang into account */
			'$store.getters.getLocale': function(){
				this.requestNewListFromAPI();
			},
		},
		mounted(){
			this.requestNewListFromAPI();
		}
	}
</script>

<!-- ================================================================================== -->


<style lang="scss" scoped>
	.selectfield {
		position: relative;
		z-index:20;
	}
	.inputmask {
		position: fixed; 
		top:0; bottom:0; right:0; left:0; 
		z-index:10;
	}
	.dropdown {
		display: inline-block;
		position: relative;
		z-index:60;
	}
	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-60%);
		right:1.25em;
		cursor: pointer;
	}
	.arrow-up {}
	.arrow-down {}
	.options-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 21;
		max-height:15em;
	}
	.dropdown .options-list {
		display: none;
	}

	.dropdown.open .options-list {
		display: block;
	}

	.options-list li {
		cursor: pointer;
		padding:0.25em;
	}

	.options-list li:hover {
		background-color: #fc16;
	}

	li.active {
		background: #fc0;
	}

</style>

