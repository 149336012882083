import {createI18n} from "vue-i18n";
import { en } from './lang/en'
import { fr } from './lang/fr'
import { nl } from './lang/nl'
import { de } from './lang/de'
import { nlnl } from './lang/nl_nl'

export default createI18n({
    /* ! These must be checked */
    /* ? could it be dynamic ? */
    locale: 'nl_BE',
    fallbackLocale: 'nl_BE',
    /* ! End check */
    messages : {
        'en_BE' : en,
        'en_UK' : en,
        'fr_BE' : fr,
        'nl_BE' : nl,
        'de_DE': de,
        'fr_LU' : fr,
        'nl_NL' : nlnl
    }
});
