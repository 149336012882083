<template>

	<div class="field" :class="[{'checkbox': specialStyle!=='default'}, specialStyle]">

		<input type="checkbox" 
		:id="inputid" 
		:name="fieldname" 
		v-model="checked" 
		:true-value="1"
		:false-value="0"
		>
		<span class="checkmark"></span>

		<label :for="inputid" :class="{'hasSuccess': checked}" class="inlineblock"><slot name="msg"></slot>&nbsp;<span v-if="isRequired" class="req">*</span></label>

		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper">{{helper}}</div>

	</div>

</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	export default {
		name:"customCheckbox",

		mixins: [FormFields],
		
		props: {
			specialStyle: {
				type: String,
				required: true,
				validator : function(value){
					return ["default", "boldborder", "fullbg", "cross", "vcheck"].includes(value);
				}
			},
			inputid: {
				type: String,
				required: false,
			}
		},
		computed: {
			checked: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.$emit('update:modelValue', newVal);
					this.$emit('runCheck', {fieldname: this.fieldname, valid: Boolean(newVal)});
				}
			}
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	input {width:auto;}
	label {
		text-align: left;
		display: block; // must be block to cover the visual box. // csq : One can click the whole line.
	}

	label.inlineblock {
		display: inline-block !important;
	}

	// special styling

	.checkbox {
		position: relative;
		margin-bottom:1em;
	}

	.checkbox input {
		position: relative;
		opacity: 0;

		position: absolute;
		left:-100vh;
		// top:-100vh; // must stay removed : forces a scroll up
		width:0;
		height:0;
	}

	.checkbox .checkmark {
		opacity:1;
		position: absolute;
		top: 0;
		left: 0px;
		width: 24px;
		height: 24px;
		background-color: #fff;
		border: 2px solid #999;
		transition: border 0.2s ease-in-out, background 0.2s ease-in-out;
		pointer-events: none;
		cursor: pointer;
	}

	.checkbox label {
		padding-left:32px;
		cursor: pointer;
	}

	.checkbox input:hover ~ .checkmark {
		background-color: #ddd;
	}

	.checkbox.boldborder input:checked ~ .checkmark {
		border: 6px solid #090;
	}

	.checkbox.fullbg input:checked ~ .checkmark {
		background: #090;
		border-color: #090;
	}

	.checkbox.vcheck input:checked ~ .checkmark {
		background: #090;
		border: #090;

		&::before, 
		&::after {
			content: '';
			display: block;
			position: absolute;
			background: #fff;
			top:50%;
			left:50%;
		}

		&::before {
			width:3px;
			height:70%;
			transform: translate(40%,-50%) rotate(35deg);
		}
		&::after {
			height:3px;
			width:40%;
			transform: translate(-95%, -40%) rotate(65deg);
		}
	}

	.checkbox.cross input:checked ~ .checkmark {

		&::before, 
		&::after {
			content: '';
			display: block;
			position: absolute;
			background: #090;
			top:50%;
			left:50%;
		}

		&::before {
			width:4px;
			height:100%;
			transform: translate(-50%,-50%) rotate(45deg);
		}
		&::after {
			height:4px;
			width:100%;
			transform: translate(-50%,-50%) rotate(45deg);
		}

	}

</style>
